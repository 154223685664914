import { createContext, useContext, useState } from "react";

export type PresignedUrlContextItem = {
  url: string;
  filePathInBucket: string;
  fileIndex: number;
  destinationId: number;
};

const PresignedPUTUrlsContext = createContext<{
  presignedPUTUrls: PresignedUrlContextItem[];
  addPresignedPUTUrls: (urls: PresignedUrlContextItem[]) => void;
  clearPresignedPUTUrls: () => void;
} | null>(null);

export const PresignedPUTUrlsProvider = ({ children }: { children: React.ReactNode }) => {
  const [presignedPUTUrls, setPresignedPUTUrls] = useState<PresignedUrlContextItem[]>([]);

  const addPresignedPUTUrls = (urls: PresignedUrlContextItem[]) => {
    setPresignedPUTUrls([...presignedPUTUrls, ...urls]);
  };

  const clearPresignedPUTUrls = () => {
    setPresignedPUTUrls([]);
  };

  return (
    <PresignedPUTUrlsContext.Provider
      value={{
        presignedPUTUrls,
        addPresignedPUTUrls,
        clearPresignedPUTUrls,
      }}
    >
      {children}
    </PresignedPUTUrlsContext.Provider>
  );
};

export const usePresignedPUTUrlsContext = () => {
  const context = useContext(PresignedPUTUrlsContext);

  if (!context) {
    throw new Error("usePresignedPUTUrlsContext must be used within a PresignedPUTUrlsProvider");
  }

  return context;
};
